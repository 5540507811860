<template>
  <section>
    <div class="row television-show-desktop">
      <div class="col-md-6 col-lg-6">
        <div class="card left-card">
          <video
            width="100%"
            controls
            height="500px"
            poster="assets/home/show-television/television-show-left.png"
          >
            <source src="assets/video/intro_video.mp4" type="video/mp4" />
          </video>
          <div class="card-body">
            <h5 class="card-title">{{ $t("general.checkout_our_biz_show_on_CRTV") }}</h5>
            <p class="card-text">
              {{ $t("general.use_our_specially_designed_training_curriculum") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6">
        <div class="card right-card">
          <div class="d-flex p-2">
            <video
              width="40%"
              style="object-fit:contain;"
              controls
              height="auto"
              poster="assets/home/show-television/television-show-right-1.png"
            >
              <source src="assets/video/intro_video.mp4" type="video/mp4" />
            </video>
            <div class="card-body p-0 pl-3">
              <p class="view-text p-0 m-0">VIEW</p>
              <h5 class="card-title">
                {{ $t("general.checkout_our_biz_show_on_CRTV_for_latest_new_and_update") }}
              </h5>
            </div>
          </div>
        </div>

        <div class="card right-card">
          <div class="d-flex p-2">
            <video
              width="40%"
              style="object-fit:contain;"
              controls
              height="auto"
              poster="assets/home/show-television/television-show-right-2.png"
            >
              <source src="assets/video/intro_video.mp4" type="video/mp4" />
            </video>
            <div class="card-body p-0 pl-3">
              <p class="view-text p-0 m-0">VIEW</p>
              <h5 class="card-title">
                {{ $t("general.checkout_our_biz_show_on_CRTV_for_latest_new_and_update") }}
              </h5>
            </div>
          </div>
        </div>

        <div class="card right-card">
          <div class="d-flex p-2">
            <video
              width="40%"
              style="object-fit:contain;"
              controls
              height="auto"
              poster="assets/home/show-television/television-show-right-3.png"
            >
              <source src="assets/video/intro_video.mp4" type="video/mp4" />
            </video>
            <div class="card-body p-0 pl-3">
              <p class="view-text p-0 m-0">VIEW</p>
              <h5 class="card-title">
                {{ $t("general.checkout_our_biz_show_on_CRTV_for_latest_new_and_update") }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row television-show-mobile">
      <splide :options="options" class="r-image">
        <splide-slide>
          <div class="col-12">
            <div class="card left-card">
              <video
                width="100%"
                controls
                height="500px"
                poster="assets/home/show-television/television-show-left.png"
              >
                <source src="assets/video/intro_video.mp4" type="video/mp4" />
              </video>
              <div class="card-body">
                <h5 class="card-title">{{ $t("general.checkout_our_biz_show_on_CRTV") }}</h5>
                <p class="card-text">
                  {{ $t("general.use_our_specially_designed_training_curriculum") }}
                </p>
              </div>
            </div>
          </div>
        </splide-slide>
        <splide-slide>
          <div class="col-12">
            <div class="card left-card">
              <video
                width="100%"
                controls
                height="500px"
                poster="assets/home/show-television/television-show-right-1.png"
              >
                <source src="assets/video/intro_video.mp4" type="video/mp4" />
              </video>
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("general.checkout_our_biz_show_on_CRTV_for_latest_new_and_update") }}
                </h5>
                <p class="card-text">
                  {{ $t("general.use_our_specially_designed_training_curriculum") }}
                </p>
              </div>
            </div>
          </div>
        </splide-slide>
        <splide-slide>
          <div class="col-12">
            <div class="card left-card">
              <video
                width="100%"
                controls
                height="500px"
                poster="assets/home/show-television/television-show-right-2.png"
              >
                <source src="assets/video/intro_video.mp4" type="video/mp4" />
              </video>
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("general.checkout_our_biz_show_on_CRTV_for_latest_new_and_update") }}
                </h5>
                <p class="card-text">
                  {{ $t("general.use_our_specially_designed_training_curriculum") }}
                </p>
              </div>
            </div>
          </div>
        </splide-slide>
        <splide-slide>
          <div class="col-12">
            <div class="card left-card">
              <video
                width="100%"
                controls
                height="500px"
                poster="assets/home/show-television/television-show-right-3.png"
              >
                <source src="assets/video/intro_video.mp4" type="video/mp4" />
              </video>
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("general.checkout_our_biz_show_on_CRTV_for_latest_new_and_update") }}
                </h5>
                <p class="card-text">
                  {{ $t("general.use_our_specially_designed_training_curriculum") }}
                </p>
              </div>
            </div>
          </div>
        </splide-slide>
      </splide>
    </div>
  </section>
</template>

<script>
import("vue-it-bigger/dist/vue-it-bigger.min.css");
export default {
  name: "TelevisionShow"
};
</script>

<style scoped>
.television-show-mobile {
  display: none !important;
}

.card {
  padding: 0 !important;
  text-align: left !important;
}

.right-card {
  margin-bottom: 15px;
  border-radius: 5px;
}

.right-card video {
  height: 130px;
  border-radius: 5px;
}

.left-card {
  border-radius: 5px;
}

.left-card video {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.right-card .card-body {
  padding: 0 1.25rem !important;
}

.left-card .card-title {
  font-size: 20px !important;
  color: black !important;
}

.right-card .card-title {
  font-size: 18px !important;
  color: black !important;
  line-height: 20px !important;
}

.card-text {
  color: black !important;
}

.view-text {
  color: rgba(231, 91, 23, 1);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .right-card {
    height: 150px;
  }

  .left-card {
    height: 480px;
  }
}

@media screen and (max-width: 425px) {
  .television-show-desktop {
    display: none !important;
  }

  .television-show-mobile {
    display: block !important;
  }
}
</style>
